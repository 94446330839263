import axios from "axios";

export const demoSiteCreate = async (id, name, email, displayName, companyName, password) => {
	try {
		const result = await axios({
			method: "post",
			url: "v2/DemoSite",
			params: {
				DonorSiteId: id,
				SiteName: name,
				UserEmailAddress: email,
				UserDisplayName: displayName,
				UserCompanyName: companyName,
				UserPassword: password,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const demoSiteDelete = async (id) => {
	try {
		const result = await axios({
			method: "delete",
			url: "v2/DemoSite",
			params: {
				DeleteSiteId: id,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};
