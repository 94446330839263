import axios from "axios";
import { CurrentSiteIdGet } from "./userSettings";

export const apiSquirrelsGetListForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/Squirrels",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSquirrelsGetList = async () => {
	const siteId = CurrentSiteIdGet();

	return await apiSquirrelsGetListForSite(siteId);
};

export const apiSquirrelChannelsGetListForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/SquirrelChannels",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSquirrelChannelsGetList = async () => {
	const siteId = CurrentSiteIdGet();

	return await apiSquirrelChannelsGetListForSite(siteId);
};

export const apiSquirrelRangesGetListForSite = async (siteId) => {
	try {
		const result = await axios({
			method: "get",
			url: "v2/SquirrelRanges",
			params: {
				SiteId: siteId,
			},
		});

		return result.data;
	} catch (err) {
		console.error(err);
	}
};

export const apiSquirrelRangesGetList = async () => {
	const siteId = CurrentSiteIdGet();

	return await apiSquirrelRangesGetListForSite(siteId);
};

export const apiSquirrelChannelsGetListForCode = async (code) => {
	const result = await axios({
		method: "get",
		url: "v2/SquirrelChannelsForCode",
		params: {
			Code: code,
		},
	});
	return result.data;
};

export const apiSquirrelChannelAction = async (channelNumber, code, action) => {
	const result = await axios({
		method: "post",
		url: "v2/SquirrelChannelAction",
		params: {
			ChannelNumber: channelNumber,
			Code: code,
			Action: action,
		},
	});
	return result.data;
};

export const apiSquirrelUpdateDarcaHubOfflineNotify = async (serialNumber, darcaHubOfflineNotify) => {
	const siteId = CurrentSiteIdGet();

	const result = await axios({
		method: "post",
		url: "v2/Squirrel",
		data: {
			siteId,
			UpdateType: "DarcaHubOfflineNotify",
			Squirrel: {
				siteId,
				serialNumber,
				darcaHubOfflineNotify,
			},
		},
	});
	return result.data;
};
