import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useState } from "react";
import { toast } from "react-toastify";
import { Button, DropdownMenu, DropdownToggle, Nav, NavItem, NavLink, TabContent, TabPane, UncontrolledDropdown } from "reactstrap";
import BatteryDoughnutImage from "../../assets/img/widgets/battery_levels_widget.png";
import BatteryTableImage from "../../assets/img/widgets/battery_levels_table.png";
import LatestValuesImage from "../../assets/img/widgets/latest_values.png";
import ChartBlueImage from "../../assets/img/widgets/chart_blue.png";
import ChartWhiteImage from "../../assets/img/widgets/chart_white.png";
import BlueGaugeImage from "../../assets/img/widgets/gauge_blue.png";
import WhiteGaugeImage from "../../assets/img/widgets/gauge_white.png";
import BlueBlockImage from "../../assets/img/widgets/meter_block_blue.png";
import WhiteBlockImage from "../../assets/img/widgets/meter_block_white.png";
import ZoneGraphicImage from "../../assets/img/widgets/zone_graphic.png";
import ZoneGraphicWideImage from "../../assets/img/widgets/zone_graphic_wide.png";
import { UserPreferencesContext } from "../../context/userPreferences";
import { logEvent } from "../../helpers/ga";
import local from "../../localization/strings";
import SiteTreeMultiSelect from "../common/SiteTreeMultiSelect";

const DashWidgetDropdown = () => {
	const [activeTab, setActiveTab] = useState(1);
	const [showSiteTree, setShowSiteTree] = useState(false);
	const [showZoneGraphicTree, setShowZoneGraphicTree] = useState(false);
	const [widgetType, setWidgetType] = useState("");

	const { pinToDashboard, pinLocationWidgetsToDashboard, pinZoneWidgetsToDashboard } = useContext(UserPreferencesContext);

	const toggle = (tab) => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const tabNavItem = (index, title, hidden) => {
		return (
			<NavItem>
				<NavLink
					hidden={hidden}
					className={activeTab === index ? "active" : ""}
					onClick={() => {
						toggle(index);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		);
	};

	const pin = async (data) => {
		if (widgetType) {
			logEvent("Pin to Dashboard", widgetType, data.join(","));
			if (widgetType === "LatestValues") {
				if (await pinLocationWidgetsToDashboard(widgetType, [data.join(",")])) {
					window.location.reload();
					toast.success(local.TF_Widgets_Added_To_Dashboard);
				} else {
					toast.error(local.TF_Widgets_Limit_Exceeded);
				}
			} else {
				if (await pinLocationWidgetsToDashboard(widgetType, data)) {
					window.location.reload();
					toast.success(local.TF_Widgets_Added_To_Dashboard);
				} else {
					toast.error(local.TF_Widgets_Limit_Exceeded);
				}
			}
		}
	};

	const pinBattery = async (batteryDisplayType) => {
		if (await pinToDashboard(batteryDisplayType, "")) {
			window.location.reload();
			toast.success(local.TF_Battery_Levels + " " + local.TF_added_to_dashboard);
		} else {
			toast.error(local.TF_Widgets_Limit_Exceeded);
		}
	};

	const toggleSiteTree = (type) => {
		setWidgetType(type);
		setShowSiteTree(!showSiteTree);
	};

	const toggleZoneGraphicSiteTree = (type) => {
		setWidgetType(type);
		setShowZoneGraphicTree(!showZoneGraphicTree);
	};

	const onZoneSelect = async (data) => {
		logEvent("Pin to Dashboard", widgetType, data.join(","));
		if (await pinZoneWidgetsToDashboard(widgetType, data)) {
			toast.success(local.TF_Widgets_Added_To_Dashboard);
			window.location.reload();
		} else {
			toast.error(local.TF_Widgets_Limit_Exceeded);
		}
	};

	const cancelWidgets = () => {
		setShowSiteTree(false);
		setWidgetType("");
	};

	return (
		<UncontrolledDropdown direction="up" className="allow-overflow float-left">
			<DropdownToggle caret transform="shrink-3" color="falcon-success">
				<FontAwesomeIcon icon="plus" />
				<span className="d-none d-md-inline-block ml-2">{local.TF_Add_Widgets}</span>
			</DropdownToggle>
			<DropdownMenu className="menu-border-blue widget-add-dropdown p-2">
				{showSiteTree ? (
					<>
						<Button size="sm" color="secondary" style={{ position: "absolute", right: 15, top: 6, zIndex: 800 }} onClick={() => cancelWidgets()}>
							{local.TS_Cancel}
						</Button>
						{widgetType === "LatestValues" ? <SiteTreeMultiSelect onFilterApply={pin} inline expandToLevel={0} siteMapLevel={3} dashPin /> : <SiteTreeMultiSelect onFilterApply={pin} inline expandToLevel={0} siteMapLevel={4} dashPin />}
					</>
				) : showZoneGraphicTree ? (
					<>
						<Button size="sm" color="secondary" style={{ position: "absolute", right: 15, top: 6, zIndex: 800 }} onClick={() => cancelWidgets()}>
							{local.TS_Cancel}
						</Button>
						<SiteTreeMultiSelect onFilterApply={onZoneSelect} onlyIfHasZoneGraphic inline expandToLevel={2} siteMapLevel={2} dashPin />
					</>
				) : (
					<>
						<Nav tabs>
							{tabNavItem(1, local.TS_Reports, false)}
							{tabNavItem(2, local.TS_Meters, false)}
							{tabNavItem(3, local.TS_Charts, false)}
							{tabNavItem(4, local.TS_ZoneGraphics, false)}
						</Nav>
						<TabContent activeTab={activeTab}>
							<TabPane tabId={1}>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => pinBattery("DashBatteryDoughnut")}>
									<img src={BatteryDoughnutImage} alt="" />
									<p className="widget-description">{local.TF_Battery_Levels}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => pinBattery("DashBatteryTable")}>
									<img src={BatteryTableImage} alt="" />
									<p className="widget-description">{local.TF_Battery_Levels}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("LatestValues")}>
									<img src={LatestValuesImage} alt="" />
									<p className="widget-description">{local.TS_LatestValues}</p>
								</Button>
							</TabPane>
							<TabPane tabId={2}>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashMeter")}>
									<img src={WhiteGaugeImage} alt="" />
									<p className="widget-description">{local.TF_Meter_Gauge}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashMeterBlue")}>
									<img src={BlueGaugeImage} alt="" />
									<p className="widget-description">
										{local.TF_Meter_Gauge} {local.TS_Blue_Brackets}
									</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashMeterBlock")}>
									<img src={WhiteBlockImage} alt="" />
									<p className="widget-description">{local.TF_Meter_Value}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashMeterBlockBlue")}>
									<img src={BlueBlockImage} alt="" />
									<p className="widget-description">
										{local.TF_Meter_Value} {local.TS_Blue_Brackets}
									</p>
								</Button>
							</TabPane>
							<TabPane tabId={3}>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashChart")}>
									<img src={ChartWhiteImage} alt="" />
									<p className="widget-description">{local.TF_Chart}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("DashChartBlue")}>
									<img src={ChartBlueImage} alt="" />
									<p className="widget-description">
										{local.TF_Chart} {local.TS_Blue_Brackets}
									</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleSiteTree("MultiChart")}>
									<img src={ChartWhiteImage} alt="" />
									<p className="widget-description">{local.TF_Combined_Chart}</p>
								</Button>
							</TabPane>
							<TabPane tabId={4}>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleZoneGraphicSiteTree("DashZoneGraphic")}>
									<img src={ZoneGraphicImage} alt="" />
									<p className="widget-description">{local.TF_Zone_Graphic}</p>
								</Button>
								<Button className="float-left m-2 widget-dropdown-block no-button" onClick={() => toggleZoneGraphicSiteTree("DashZoneGraphicWide")}>
									<img src={ZoneGraphicWideImage} alt="" />
									<p className="widget-description">
										{local.TF_Zone_Graphic} {local.TF_Wide_brackets}
									</p>
								</Button>
							</TabPane>
						</TabContent>
					</>
				)}
			</DropdownMenu>
		</UncontrolledDropdown>
	);
};

export default DashWidgetDropdown;
