import { faBars, faCheckSquare, faChevronDown, faChevronRight, faCloud, faFile, faFolder, faFolderOpen, faMinusSquare, faPlusSquare, faRouter, faSquare, faWarehouse } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import { siteMap } from "../../api/site";
import Loader from "../common/Loader";

const SiteTreeMenu = ({ onClick, expandToLevel, siteMapLevel = 3, inline = false, onlyIfHasZoneGraphic = false, siteTreeFound }) => {
	const [nodes, setNodes] = useState([]);
	const [expanded, setExpanded] = useState([]);
	const [loading, setLoading] = useState(true);

	const setExpandedCallback = useCallback(
		(data) => {
			const getNodeIds = (nodes) => {
				let ids = [];

				nodes.forEach(({ value, nodeLevel, children }) => {
					if (nodeLevel <= expandToLevel) {
						ids = [...ids, value, ...getNodeIds(children)];
					}
				});

				return ids;
			};

			var ids = getNodeIds(data);

			setExpanded(ids);
		},
		[expandToLevel],
	);

	useEffect(() => {
		let isSubscribed = true;

		async function loadSiteMap() {
			var data = await siteMap(siteMapLevel, onlyIfHasZoneGraphic, false);

			if (isSubscribed) {
				fixIcons(data);

				setNodes(data);

				setExpandedCallback(data);

				if (siteTreeFound) {
					if (data[0] && data[0].children.length !== 0) {
						siteTreeFound(true);
					} else {
						siteTreeFound(false);
					}
				}

				setLoading(false);

				var reactTree = document.getElementsByClassName("react-checkbox-tree")[0];
				if (reactTree) {
					reactTree.scrollTo(0, 0);
				}
			}
		}

		function fixIcons(data) {
			data.forEach(function(node) {
				switch (node.nodeLevel) {
					case 0:
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faCloud} fixedWidth />;
						break;
					case 1:
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faWarehouse} fixedWidth />;
						break;
					case 2:
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faBars} fixedWidth />;
						break;
					case 3:
						node.icon = <FontAwesomeIcon className="rct-icon rct-icon-leaf-close gradient-icons" size="lg" icon={faRouter} fixedWidth />;
						break;
					default:
						node.icon = "";
						break;
				}

				fixIcons(node.children);
			});
		}

		loadSiteMap();

		return () => (isSubscribed = false);
	}, [setExpandedCallback, siteMapLevel, expandToLevel, onlyIfHasZoneGraphic, siteTreeFound]);

	const click = (e) => {
		if (onClick) {
			if (e.isLeaf) {
				onClick(e.value, e.label, e.parent.label);
			}
		}
	};

	return (
		<>
			{loading ? (
				<div className={inline ? "" : "card"}>
					<Loader />
				</div>
			) : (
				<div className={`p-2 ${inline ? "" : "card mt-2"}`}>
					<CheckboxTree
						nodes={nodes}
						expanded={expanded}
						onExpand={(expanded) => setExpanded(expanded)}
						showNodeIcon={true}
						showExpandAll={false}
						optimisticToggle={true}
						expandOnClick={true}
						onClick={click}
						icons={{
							check: <FontAwesomeIcon className="rct-icon rct-icon-check" icon={faCheckSquare} size="lg" fixedWidth />,
							uncheck: <FontAwesomeIcon className="rct-icon rct-icon-uncheck" icon={faSquare} size="lg" fixedWidth />,
							halfCheck: <FontAwesomeIcon className="rct-icon rct-icon-half-check" icon={faCheckSquare} size="lg" fixedWidth />,
							expandClose: <FontAwesomeIcon className="rct-icon rct-icon-expand-close" icon={faChevronRight} size="lg" fixedWidth />,
							expandOpen: <FontAwesomeIcon className="rct-icon rct-icon-expand-open" icon={faChevronDown} size="lg" fixedWidth />,
							expandAll: <FontAwesomeIcon className="rct-icon rct-icon-expand-all" icon={faPlusSquare} size="lg" fixedWidth />,
							collapseAll: <FontAwesomeIcon className="rct-icon rct-icon-collapse-all" icon={faMinusSquare} size="lg" fixedWidth />,
							parentClose: <FontAwesomeIcon className="rct-icon rct-icon-parent-close" icon={faFolder} size="lg" fixedWidth />,
							parentOpen: <FontAwesomeIcon className="rct-icon rct-icon-parent-open" icon={faFolderOpen} size="lg" fixedWidth />,
							leaf: <FontAwesomeIcon className="rct-icon rct-icon-leaf-close" icon={faFile} size="lg" fixedWidth />,
						}}
					/>
				</div>
			)}
		</>
	);
};

export default SiteTreeMenu;
